import React from "react";

import { IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import BlogCardActions from "@/blogs/components/BlogCardActions";
import BlogCard from "@/common/components/BlogCard";
import DebateCard from "@/common/components/DebateCard";
import EventCard from "@/common/components/EventCard";
import GroupCard from "@/common/components/GroupCard";
import PetitionCard from "@/common/components/PetitionCard";
import DebateCardActions from "@/debates/components/DebateCardActions";
import CardActions from "@/events/components/CardActions";
import GroupCardActions from "@/groups/components/GroupCardActions";
import LiveStreamCard from "@/liveStreams/components/LiveStreamCard/LiveStreamCard";
import LivestreamCardActions from "@/liveStreams/components/LivestreamCardActions";
import PetitionCardActions from "@/petitions/components/PetitionCardActions";

import { useGroupsStore } from "@/groups/hooks/use-groups-store";
import {
  MaybePinnedTimelineItem,
  useProfileTimelineStore,
} from "@/profile/hooks/use-profile-timeline-store";

import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import {
  GroupTimelineItemType,
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  UserTimelineItemType,
} from "@/graphql/types";

import PinnedPostIcon from "../icons/PinnedPostIcon";
import { TimelineRootType } from "../models/Enums";

const useStyles = makeStyles()(() => ({
  pinIcon: {
    marginRight: "12px",
    padding: 0,
  },
}));

type ExtractProps<TComponentOrTProps> = TComponentOrTProps extends React.ComponentType<infer TProps>
  ? TProps
  : TComponentOrTProps;

type ActivityCardProps = {
  activity: MaybePinnedTimelineItem<
    | ActivityCardFragment
    | ActivityCardSlimFragment
    | LiveStreamCardFragment
    | LiveStreamCardSlimFragment
  >;
  showAuthor?: boolean;
  hideBelonging?: boolean;
  hotTrend?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
  pinnable?: boolean;
  timelineRootType?: TimelineRootType;
  cardType?: "normal" | "vertical";
  hideMenu?: boolean;
} & Omit<ExtractProps<typeof GroupCard>, "group"> &
  Omit<ExtractProps<typeof DebateCard>, "debate"> &
  Omit<ExtractProps<typeof EventCard>, "event"> &
  Omit<ExtractProps<typeof BlogCard>, "blog"> &
  Omit<ExtractProps<typeof PetitionCard>, "petition">;

const ActivityCard = ({
  activity,
  hideHeader,
  hideHeaderIcon,
  cardType = "normal",
  timelineRootType,
  ...props
}: ActivityCardProps) => {
  const { t } = useTranslation();
  const { triggerItemPin } = useProfileTimelineStore();
  const { triggerGroupItemPin } = useGroupsStore();
  const { classes } = useStyles();
  const canPin = (activity && activity.author?.isMe) || false;

  const handlePin = (type: UserTimelineItemType, groupType: GroupTimelineItemType) => () => {
    if (timelineRootType === TimelineRootType.Profile) {
      triggerItemPin(activity.id, type);
    } else if (timelineRootType === TimelineRootType.Activity) {
      triggerGroupItemPin(activity.id, groupType);
    }
  };

  const PinIcon = ({ handlePin }: { handlePin: () => void }) =>
    activity.pinned && props.pinnable ? (
      <IconButton size="small" onClick={handlePin} classes={{ root: classes.pinIcon }}>
        <PinnedPostIcon />
      </IconButton>
    ) : null;

  const actionMenu = (type: UserTimelineItemType, groupType: GroupTimelineItemType) => [
    ...(canPin
      ? [
          {
            label: !activity.pinned ? t("Timeline.PinToTop") : t("Timeline.Unpin"),
            onClick: handlePin(type, groupType),
          },
        ]
      : []),
  ];

  switch (activity?.__typename) {
    case "Group":
      return (
        <GroupCard
          {...props}
          group={activity}
          hideHeader={hideHeader}
          hideHeaderIcon={hideHeaderIcon}
          actionComponent={
            <PinIcon
              handlePin={handlePin(UserTimelineItemType.Group, GroupTimelineItemType.Group)}
            />
          }
          actionMenu={actionMenu(UserTimelineItemType.Group, GroupTimelineItemType.Group)}
          cardActionsMenu={
            <GroupCardActions group={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    case "Debate":
      return (
        <DebateCard
          {...props}
          debate={activity}
          hideHeader={hideHeader}
          hideHeaderIcon={hideHeaderIcon}
          actionComponent={
            <PinIcon
              handlePin={handlePin(UserTimelineItemType.Debate, GroupTimelineItemType.Debate)}
            />
          }
          actionMenu={actionMenu(UserTimelineItemType.Debate, GroupTimelineItemType.Debate)}
          cardActionsMenu={
            <DebateCardActions debate={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    case "Event":
      return (
        <EventCard
          {...props}
          event={activity}
          hideHeader={hideHeader}
          hideHeaderIcon={hideHeaderIcon}
          actionComponent={
            <PinIcon
              handlePin={handlePin(UserTimelineItemType.Event, GroupTimelineItemType.Event)}
            />
          }
          actionMenu={actionMenu(UserTimelineItemType.Event, GroupTimelineItemType.Event)}
          cardActionsMenu={
            <CardActions event={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    case "Blog":
      return (
        <BlogCard
          {...props}
          blog={activity}
          hideHeader={hideHeader}
          hideHeaderIcon={hideHeaderIcon}
          actionComponent={
            <PinIcon handlePin={handlePin(UserTimelineItemType.Blog, GroupTimelineItemType.Blog)} />
          }
          actionMenu={actionMenu(UserTimelineItemType.Blog, GroupTimelineItemType.Blog)}
          cardActionsMenu={
            <BlogCardActions blog={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    case "Petition":
      return (
        <PetitionCard
          {...props}
          petition={activity}
          hideHeader={hideHeader}
          hideHeaderIcon={hideHeaderIcon}
          actionComponent={
            <PinIcon
              handlePin={handlePin(UserTimelineItemType.Petition, GroupTimelineItemType.Petition)}
            />
          }
          actionMenu={actionMenu(UserTimelineItemType.Petition, GroupTimelineItemType.Petition)}
          cardActionsMenu={
            <PetitionCardActions petition={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    case "LiveStream":
      return (
        <LiveStreamCard
          liveStream={activity}
          small
          showFollowButton={true}
          actionComponent={
            <PinIcon
              handlePin={handlePin(
                UserTimelineItemType.LiveStream,
                GroupTimelineItemType.LiveStream,
              )}
            />
          }
          actionMenu={actionMenu(UserTimelineItemType.LiveStream, GroupTimelineItemType.LiveStream)}
          cardActionsMenu={
            <LivestreamCardActions livestream={activity} isOwner={activity.author?.isMe || false} />
          }
          cardType={cardType}
        />
      );
    default:
      return null;
  }
};

export default ActivityCard;
