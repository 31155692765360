import { useRouter } from "next/router";
import React from "react";

import { Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import { useStyles as useContentCardStyles } from "@/common/components/ContentCard.styles";
import Hide from "@/common/components/Hide";
import Link from "@/common/components/Link";

import CalendarIcon from "@/common/icons/CalendarIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useEventsFollowersStore } from "@/events/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import {
  UserBaseFragment,
  EventCardFragment,
  EventCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

import { getFullAuthorName } from "../services/fullName";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexWrap: "nowrap",
  },
  mediaIcon: {
    width: "52%",
    height: "52%",
    color: "#3F3D56",
    fill: "#3F3D56",
  },
  locationText: {
    color: theme.palette.common.black,
  },
  subtitleDiv: {
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  subtitleDivContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  subtitleDivLocation: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
  },
  subtitleDivDot: {
    marginRight: theme.spacing(1),
  },
  dates: {
    fontWeight: 600,
    color: theme.mode.text.heading,
  },
  attenders: {
    color: theme.mode.text.heading,
  },
  dateArrow: {
    margin: theme.spacing(0, 1),
  },
  description: {
    color: theme.mode.text.description,
  },
  text: {
    fontWeight: 400,
    overflow: "hidden",
    display: "-webkit-box",
    wordWrap: "break-word",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
  },
  belongingLink: {
    fontWeight: 800,
  },
  mediaIconVertical: {
    height: "72px",
  },
}));

const EventCard = ({
  event,
  showFriends,
  showAuthor,
  hideBelonging,
  hideHeader = false,
  hideHeaderIcon = false,
  cardType,
  ...props
}: {
  event: EventCardFragment | EventCardSlimFragment;
  showFriends?: boolean;
  showAuthor?: boolean;
  hideBelonging?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
} & Partial<ContentCardProps>) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { classes: contentCardClasses } = useContentCardStyles();
  const { toggleFollow, undoFollowRequest } = useEventsFollowersStore(event);
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const router = useRouter();

  const hasParentGroup = !!event.activityGroup?.url;

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (event.author?.isMe) {
      router.push(`/events/${event.slug}/edit`);
      return;
    }
    if (event.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      toggleFollow();
    }
  };

  const Media = event.cover
    ? () => (
        <img
          src={event.cover?.url}
          alt="Event"
          className={cx(contentCardClasses.mediaCover, {
            [contentCardClasses.mediaCoverVertical]: cardType === "vertical",
          })}
        />
      )
    : () => (
        <CalendarIcon
          className={cx(classes.mediaIcon, {
            [classes.mediaIconVertical]: cardType === "vertical",
          })}></CalendarIcon>
      );

  const getActionText = () => {
    if (session?.user?.id === event.author?.id) {
      return t("Event.EditEvent");
    }
    if (event.follows.hasBeenRequestedByMe) {
      return t("Generic.Requested");
    }
    return event.follows.isFollowedByMe ? t("Event.AttendingLabel") : t("Event.Attend");
  };

  const getCardSubtitle = () => {
    return (
      <Typography
        variant="h6"
        noWrap
        className={cx(classes.locationText, contentCardClasses.subtitle)}>
        <div className={classes.subtitleDivContainer}>
          <div className={classes.subtitleDivLocation}>{event.location?.address}</div>
          <div className={classes.subtitleDivDot}>•</div>
          <div>
            <Hide if={!showFriends}>
              <Moment format="MMM DD YYYY">{event.parsedStartTime?.date}</Moment>
            </Hide>
            <Hide if={showFriends || false}>
              {t("Event.PeopleAttending", {
                count: event.follows.followers.count,
              })}
            </Hide>
          </div>
        </div>
        {showAuthor && event.author && (
          <>
            {t("Generic.CreatedBy")} {getFullAuthorName(event.author as UserBaseFragment)}
          </>
        )}
      </Typography>
    );
  };

  const followersText = t("Event.PeopleAttending", {
    count: event.follows.followers.count,
  });

  return (
    <ContentCard
      header={hideHeader ? undefined : t("Discover.Events")}
      headerIcon={hideHeaderIcon ? undefined : CalendarIcon}
      headerIconVertical={<CalendarIcon color="#3f3d56" />}
      media={<Media />}
      actionText={getActionText()}
      actionTextUncolored={
        (event.follows.isFollowedByMe || event.follows.hasBeenRequestedByMe) &&
        session?.user?.id !== event.author?.id
      }
      title={event.title}
      subtitleComponent={getCardSubtitle}
      text={event.description || ""}
      onAction={handleActionClick}
      titleLink={event.url}
      descriptionComponent={
        <>
          <Typography variant="h6" noWrap className={classes.dates}>
            <Moment format="MMM DD, YYYY">{event.parsedStartTime?.date.toString()}</Moment>
            <Hide if={false}>
              <span className={classes.dateArrow}>{"→"}</span>
              <Moment format="MMM DD, YYYY">{event.parsedEndTime?.date.toString()}</Moment>
            </Hide>
          </Typography>
          <div className={classes.description}>
            <Typography variant="h6" className={classes.text}>
              {event.description}
            </Typography>
          </div>
          {hideBelonging ||
            (event.activityGroup && (
              <>
                {t("Generic.CreatedBy")}{" "}
                <Link href={event.activityGroup.url || ""} passHref>
                  <a className={classes.belongingLink}>{event.activityGroup.title}</a>
                </Link>
              </>
            ))}
        </>
      }
      dataCy="EventCard"
      cardType={cardType}
      createdByLabel={hasParentGroup ? event.activityGroup?.title : event.author?.displayName}
      createdByLink={hasParentGroup ? event.activityGroup?.url : event.author?.url}
      createdByIsVerified={!hasParentGroup && event.author?.verified}
      followersText={followersText}
      canShowFollowersList={event.follows.followers.count > 0}
      activityId={event.id}
      activityType={ExtendedActivityType.Event}
      numberFollowers={event.follows.followers.count}
      {...props}
    />
  );
};
export default EventCard;
