import { useRouter } from "next/router";
import React from "react";

import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import { useStyles as useContentCardStyles } from "@/common/components/ContentCard.styles";
import Link from "@/common/components/Link";

import ConversationIcon from "@/common/icons/ConversationIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useDebatesFollowersStore } from "@/debates/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import { getFullAuthorName } from "@/common/services/fullName";
import {
  UserBaseFragment,
  DebateCardFragment,
  DebateCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

const useStyles = makeStyles()(() => ({
  mediaIcon: {
    width: "52%",
    height: "52%",
    color: "#3F3D56",
    fill: "#3F3D56",
  },
  belongingLink: {
    fontWeight: 800,
  },
  mediaIconVertical: {
    height: "72px",
  },
  text: {
    fontSize: ".9375rem",
    lineHeight: "1.25rem",
    WebkitLineClamp: "2",
    display: "-webkit-box",
    overflowWrap: "anywhere",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const DebateCard = ({
  debate,
  showAuthor,
  hideBelonging,
  hideHeader = false,
  hideHeaderIcon = false,
  cardType,
  ...props
}: {
  debate: DebateCardFragment | DebateCardSlimFragment;
  showAuthor?: boolean;
  hideBelonging?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
} & Partial<ContentCardProps>) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { classes: contentCardClasses } = useContentCardStyles();
  const { toggleFollow, undoFollowRequest } = useDebatesFollowersStore(debate);
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const router = useRouter();

  const hasParentGroup = !!debate.activityGroup?.url;

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (debate.author?.isMe) {
      router.push(`/debates/${debate.slug}/edit`);
      return;
    }
    if (debate.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      toggleFollow();
    }
  };

  const Media = debate.cover
    ? () => (
        <img
          src={debate.cover?.url}
          alt="Debate"
          className={cx(contentCardClasses.mediaCover, {
            [contentCardClasses.mediaCoverVertical]: cardType === "vertical",
          })}
        />
      )
    : () => (
        <ConversationIcon
          className={cx(classes.mediaIcon, {
            [classes.mediaIconVertical]: cardType === "vertical",
          })}></ConversationIcon>
      );

  const count = debate.follows.followers.count;

  const followersText =
    count === 1
      ? t("Common.participant_count", { count })
      : t("Common.participant_count_plural", { count });

  const getSubtitleText = () => {
    const subtitle =
      count === 1
        ? t("Common.participant_count", { count })
        : t("Common.participant_count_plural", { count });

    return (
      <>
        {subtitle}
        {showAuthor && debate.author && (
          <>
            <br />
            {t("Generic.CreatedBy")} {getFullAuthorName(debate.author as UserBaseFragment)}
          </>
        )}
      </>
    );
  };

  const getActionText = () => {
    if (session?.user?.id === debate.author?.id) {
      return t("Debates.EditDebate");
    }
    if (debate.follows.hasBeenRequestedByMe) {
      return t("Generic.Requested");
    }
    return debate.follows.isFollowedByMe ? t("Generic.NonParticipate") : t("Generic.Participate");
  };

  return (
    <ContentCard
      header={hideHeader ? undefined : t("Discover.Debates")}
      headerIcon={hideHeaderIcon ? undefined : ConversationIcon}
      headerIconVertical={<ConversationIcon color="#3f3d56" />}
      title={debate.title}
      subtitle={getSubtitleText()}
      text={debate.description || ""}
      media={<Media />}
      actionText={getActionText()}
      actionTextUncolored={
        (debate.follows.isFollowedByMe || debate.follows.hasBeenRequestedByMe) &&
        session?.user?.id !== debate.author?.id
      }
      onAction={handleActionClick}
      descriptionComponent={
        <>
          {hideBelonging ||
            (debate.activityGroup && (
              <>
                {t("Generic.CreatedBy")}{" "}
                <Link href={debate.activityGroup.url || ""} passHref>
                  <a className={classes.belongingLink}>{debate.activityGroup.title}</a>
                </Link>
              </>
            ))}
          {debate.description && <p className={classes.text}>{debate.description}</p>}
        </>
      }
      titleLink={debate.url}
      dataCy="DebateCard"
      cardType={cardType}
      createdByLabel={hasParentGroup ? debate.activityGroup?.title : debate.author?.displayName}
      createdByLink={hasParentGroup ? debate.activityGroup?.url : debate.author?.url}
      createdByIsVerified={!hasParentGroup && debate.author?.verified}
      followersText={followersText}
      canShowFollowersList={count > 0}
      activityId={debate.id}
      activityType={ExtendedActivityType.Debate}
      numberFollowers={count}
      {...props}
    />
  );
};
export default DebateCard;
