import React, { SVGProps } from "react";

import { useTheme } from "@mui/material";

const PinnedPostIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M14.876 16.897c.872-1.122 1.174-2.565.924-3.97l2.488-3.67a2.54 2.54 0 0 0 1.067-.481c.133-.104.132-.31.003-.439l-3.695-3.695c-.13-.13-.335-.13-.439.003a2.538 2.538 0 0 0-.481 1.067L11.073 8.2c-1.405-.25-2.848.052-3.97.924-.25.194-.25.582-.006.825l2.613 2.614-4.299 4.717c-.307.337-.272.88.079 1.23.35.351.893.386 1.23.079l4.717-4.3 2.614 2.614c.243.244.63.244.825-.006"
          fill={theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};

export default PinnedPostIcon;
