import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";

import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import { useStyles as useContentCardStyles } from "@/common/components/ContentCard.styles";
import Link from "@/common/components/Link";
import PetitionRemainingProgress from "@/petitions/components/PetitionRemainingProgress";
import { SignatoryNameDialogProps } from "@/petitions/components/SignatoryNameDialog/SignatoryNameDialog";

import PetitionsIcon from "@/common/icons/PetitionsIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { usePetitionsFollowersStore } from "@/petitions/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import {
  UserBaseFragment,
  PetitionCardFragment,
  PetitionCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

import { bindDialogState, useDialog } from "../hooks";
import { getFullAuthorName } from "../services/fullName";

const SignatoryNameDialog = dynamic<SignatoryNameDialogProps>(
  () =>
    import("@/petitions/components/SignatoryNameDialog/SignatoryNameDialog").then(
      (module) => module.SignatoryNameDialog,
    ),
  {
    ssr: false,
  },
);
const useStyles = makeStyles()(() => ({
  mediaIcon: {
    width: "52%",
    height: "52%",
    color: "#3F3D56",
    fill: "#3F3D56",
  },
  belongingLink: {
    fontWeight: 800,
  },
  progressWrapper: { margin: "5px 0" },
  mediaIconVertical: {
    height: "72px",
  },
}));

const PetitionCard = ({
  petition,
  showAuthor,
  hideBelonging,
  hideHeader = false,
  hideHeaderIcon = false,
  cardType,
  ...props
}: {
  petition: PetitionCardFragment | PetitionCardSlimFragment;
  showAuthor?: boolean;
  hideBelonging?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
} & Partial<ContentCardProps>) => {
  const { classes, cx } = useStyles();
  const { classes: contentCardClasses } = useContentCardStyles();
  const { t } = useTranslation();
  const { undoFollowRequest } = usePetitionsFollowersStore(petition);
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const router = useRouter();
  const signatoryNameDialog = useDialog();

  const hasParentGroup = !!petition.activityGroup?.url;

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (petition.author?.isMe) {
      router.push(`/petitions/${petition.slug}/edit`);
      return;
    }
    if (petition.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      signatoryNameDialog.open();
    }
  };

  const getActionText = () => {
    if (session?.user?.id === petition.author?.id) {
      return t("Petitions.EditPetition");
    }
    if (petition.follows.hasBeenRequestedByMe) {
      return t("Generic.Requested");
    }
    return petition.follows.isFollowedByMe
      ? t("Petitions.PetitionSigned")
      : t("Petitions.Sign.Verb");
  };

  const Media = petition.cover
    ? () => (
        <img
          src={petition.cover?.url}
          alt="Petition"
          className={cx(contentCardClasses.mediaCover, {
            [contentCardClasses.mediaCoverVertical]: cardType === "vertical",
          })}
        />
      )
    : () => (
        <PetitionsIcon
          className={cx(classes.mediaIcon, {
            [classes.mediaIconVertical]: cardType === "vertical",
          })}></PetitionsIcon>
      );
  const remaining =
    Math.max(petition.minimumSignaturesCount - petition.activityProp.followersCount, 0) || "no";
  const followersText =
    remaining === 1
      ? t("Petitions.Need1MoreSignature")
      : t("Petitions.NeedMoreSignatures", { nr: remaining });
  return (
    <>
      <ContentCard
        header={hideHeader ? undefined : t("Discover.Petitions")}
        headerIcon={hideHeaderIcon ? undefined : PetitionsIcon}
        headerIconVertical={<PetitionsIcon color="#3f3d56" />}
        title={petition.title}
        text={petition.description || ""}
        subtitle={
          showAuthor && petition.author
            ? `${t("Generic.CreatedBy")} ${getFullAuthorName(petition.author as UserBaseFragment)}`
            : undefined
        }
        media={<Media />}
        actionText={getActionText()}
        actionTextUncolored={
          petition.follows.hasBeenRequestedByMe && session?.user?.id !== petition.author?.id
        }
        onAction={handleActionClick}
        actionDisabled={petition.follows.isFollowedByMe && !petition.author?.isMe}
        titleLink={petition.url}
        descriptionComponent={
          <>
            {hideBelonging ||
              (petition.activityGroup && (
                <>
                  {t("Generic.CreatedBy")}{" "}
                  <Link href={petition.activityGroup.url || ""} passHref>
                    <a className={classes.belongingLink}>{petition.activityGroup.title}</a>
                  </Link>
                </>
              ))}
            <div className={classes.progressWrapper}>
              <PetitionRemainingProgress
                displayRemainingNumber
                signaturesNeeded={petition.minimumSignaturesCount}
                signaturesReceived={petition.activityProp.followersCount}
              />
            </div>
          </>
        }
        dataCy="PetitionCard"
        cardType={cardType}
        createdByLabel={
          hasParentGroup ? petition.activityGroup?.title : petition.author?.displayName
        }
        createdByLink={hasParentGroup ? petition.activityGroup?.url : petition.author?.url}
        createdByIsVerified={!hasParentGroup && petition.author?.verified}
        followersText={followersText}
        canShowFollowersList={petition.activityProp.followersCount > 0}
        activityId={petition.id}
        activityType={ExtendedActivityType.Petition}
        numberFollowers={petition.activityProp.followersCount}
        {...props}
      />
      <SignatoryNameDialog {...bindDialogState(signatoryNameDialog)} petition={petition} />
    </>
  );
};
export default PetitionCard;
