import { useRouter } from "next/router";
import React from "react";

import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import { useStyles as useContentCardStyles } from "@/common/components/ContentCard.styles";

import GroupsIcon from "@/common/icons/GroupsIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useGroupsFollowersStore } from "@/groups/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import {
  UserBaseFragment,
  GroupCardFragment,
  GroupCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

import { getFullAuthorName } from "../services/fullName";

const useStyles = makeStyles()(() => ({
  mediaIcon: {
    width: "52%",
    height: "52%",
    color: "#3F3D56",
    fill: "#3F3D56",
  },
  mediaIconVertical: {
    height: "72px",
  },
}));

const GroupCard = ({
  group,
  showAuthor,
  hideHeader = false,
  hideHeaderIcon = false,
  cardType,
  ...props
}: {
  group: GroupCardFragment | GroupCardSlimFragment;
  showAuthor?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
} & Partial<ContentCardProps>) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { classes: contentCardClasses } = useContentCardStyles();
  const { toggleFollow, undoFollowRequest } = useGroupsFollowersStore(group);
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const router = useRouter();

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (group.author?.isMe) {
      router.push(`/groups/${group.slug}/edit`);
      return;
    }
    if (group.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      toggleFollow();
    }
  };

  const Media = group.cover
    ? () => (
        <img
          src={group.cover?.url}
          alt="Group"
          className={cx(contentCardClasses.mediaCover, {
            [contentCardClasses.mediaCoverVertical]: cardType === "vertical",
          })}
        />
      )
    : () => (
        <GroupsIcon
          className={cx(classes.mediaIcon, {
            [classes.mediaIconVertical]: cardType === "vertical",
          })}></GroupsIcon>
      );

  const getActionText = () => {
    if (session?.user?.id === group.author?.id) {
      return t("Group.EditGroup");
    }
    if (group.follows.hasBeenRequestedByMe) {
      return t("Generic.Requested");
    }
    return group.follows.isFollowedByMe ? t("Generic.Unjoin") : t("Generic.Join");
  };

  const count = group.follows.followers.count;

  const followersText =
    count === 1 ? t("Common.member_count", { count }) : t("Common.member_count_plural", { count });

  const getSubtitleText = () => {
    const subtitle =
      count === 1
        ? t("Common.member_count", { count })
        : t("Common.member_count_plural", { count });

    return (
      <>
        {subtitle}
        {showAuthor && group.author && (
          <>
            <br />
            {t("Generic.CreatedBy")} {getFullAuthorName(group.author as UserBaseFragment)}
          </>
        )}
      </>
    );
  };

  return (
    <ContentCard
      dataCy="GroupCard"
      header={hideHeader ? undefined : t("Discover.Groups")}
      headerIcon={hideHeaderIcon && cardType == "normal" ? undefined : GroupsIcon}
      headerIconVertical={<GroupsIcon />}
      title={group.title}
      subtitle={getSubtitleText()}
      text={group.description || ""}
      media={<Media />}
      actionText={getActionText()}
      actionTextUncolored={
        (group.follows.isFollowedByMe || group.follows.hasBeenRequestedByMe) &&
        session?.user?.id !== group.author?.id
      }
      onAction={handleActionClick}
      descriptionComponent={undefined}
      titleLink={group.url}
      cardType={cardType}
      createdByLabel={group.author?.displayName}
      createdByLink={group.author?.url}
      createdByIsVerified={group.author?.verified}
      followersText={followersText}
      canShowFollowersList={count > 0}
      activityId={group.id}
      activityType={ExtendedActivityType.Group}
      numberFollowers={count}
      {...props}
    />
  );
};
export default GroupCard;
