import {
  User,
  useProfileTimelineQuery,
  useTriggerTimelineItemPinMutation,
  UserTimelineItemType,
} from "@/graphql/types";

export type MaybePinnedTimelineItem<T> = T & { pinned?: boolean };

export const useProfileTimelineStore = ({
  limit = 10,
  user,
}: { limit?: number; user?: Pick<User, "id"> } = {}) => {
  const { data, fetchMore, loading } = useProfileTimelineQuery({
    variables: { userId: user?.id as string, limit },
    skip: !user?.id,
  });

  const [triggerItemPin] = useTriggerTimelineItemPinMutation({
    refetchQueries: [
      "GetUserPosts",
      "GetBlogPosts",
      "GetEventPosts",
      "GetGroupPosts",
      "GetPetitionPosts",
      "ProfileTimeline",
    ],
  });

  return {
    items:
      data?.userTimelineItems?.edges?.map(({ node, pinned }) => ({ ...node, pinned } as const)) ||
      [],
    fetchNextTimelinePage: () =>
      fetchMore({
        variables: {
          after: data?.userTimelineItems?.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            userTimelineItems: {
              ...fetchMoreResult.userTimelineItems,
              edges: [...prev.userTimelineItems.edges, ...fetchMoreResult.userTimelineItems.edges],
            },
          };
        },
      }),
    count: data?.userTimelineItems?.count || 0,
    loading,
    hasMorePages: data?.userTimelineItems?.pageInfo?.hasNextPage || false,
    triggerItemPin: (id: string, type: UserTimelineItemType) =>
      triggerItemPin({ variables: { id, itemType: type } }),
  } as const;
};
