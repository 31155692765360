import dynamic from "next/dynamic";

import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import { useStyles } from "@/common/components/ContentCard.styles";
import type { InvitePeopleDialogProps } from "@/common/components/InvitePeopleDialog";
import type { ReportAbuseDialogProps } from "@/common/components/ReportAbuseDialog";

import { useAuthenticationStore } from "@/authentication/hooks";
import { bindDialogState, useDialog } from "@/common/hooks";

import { SITE_URL } from "@/common/constants";
import { ReportActivities } from "@/common/models/Enums";
import { ReportReason } from "@/common/models/Report";
import { useSnackbarContext } from "@/common/providers";
import { copyToClipboard } from "@/common/services";
import {
  DebateCardFragment,
  DebateCardSlimFragment,
  ExtendedActivityType,
  Invitable,
} from "@/graphql/types";

import { useDebatesFollowersStore, useDebatesReportsStore } from "../hooks";
const InvitePeopleDialog = dynamic<InvitePeopleDialogProps>(
  () =>
    import("@/common/components/InvitePeopleDialog").then((module) => module.InvitePeopleDialog),
  {
    ssr: false,
  },
);
const ReportAbuseDialog = dynamic<ReportAbuseDialogProps>(
  () => import("@/common/components/ReportAbuseDialog").then((module) => module.ReportAbuseDialog),
  {
    ssr: false,
  },
);

type DebateCardActionsProps = {
  debate: DebateCardFragment | DebateCardSlimFragment;
  isOwner: boolean;
};

const DebateCardActions = ({ debate, isOwner }: DebateCardActionsProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { report } = useDebatesReportsStore(debate);
  const { isUserLoggedIn } = useAuthenticationStore();
  const dialogState = useDialog<ReportReason>();
  const invitePeopleDialogState = useDialog();
  const { invitePeople } = useDebatesFollowersStore(debate);
  const isReportingAvailable = isUserLoggedIn && !isOwner;
  const menuState = usePopupState({ variant: "popover", popupId: "cardMenu" });
  const { open: openSnackbar } = useSnackbarContext();

  const handleReport = () => {
    dialogState.open();
    menuState.close();
  };

  const handleCopyUrl = () => {
    copyToClipboard(`${SITE_URL}${debate.url}/`);
    menuState.close();
    openSnackbar({
      open: true,
      message: t("App.Activity.UrlCopied"),
      delay: 3500,
    });
  };

  const handleInvite = () => {
    invitePeopleDialogState.open();
    menuState.close();
  };

  return (
    <>
      <div>
        <IconButton
          size="small"
          classes={{ root: classes.moreActionIcon }}
          {...bindTrigger(menuState)}
          data-cy="card-more-actions-button">
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...bindMenu(menuState)}>
          {isUserLoggedIn && (
            <MenuItem onClick={() => handleInvite()}>{t("Common.Actions.Invite")}</MenuItem>
          )}
          <MenuItem onClick={() => handleCopyUrl()}>{t("Common.Actions.CopyUrl")}</MenuItem>
          {isReportingAvailable && (
            <MenuItem onClick={() => handleReport()}>{t("Debates.Actions.Report")}</MenuItem>
          )}
        </Menu>
        <ReportAbuseDialog
          {...bindDialogState(dialogState)}
          type={ReportActivities.genericActivity}
          onReport={report}
          user={debate.author}
          activityId={debate.id}
          activityTitle={debate.title}
          activityType={ExtendedActivityType.Debate}
          activityFollowedByMe={debate.follows.isFollowedByMe}
        />
        <InvitePeopleDialog
          {...bindDialogState(invitePeopleDialogState)}
          onInvite={invitePeople}
          invitableType={Invitable.Debate}
          invitableId={debate.id}
        />
      </div>
    </>
  );
};

export default DebateCardActions;
