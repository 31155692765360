import React from "react";

import { LinearProgress, Theme, Typography } from "@mui/material";
import { withStyles, makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: "100%",
  },
}));

const BorderLinearProgress = withStyles(LinearProgress, (theme: Theme) => ({
  root: {
    height: 12,
    borderRadius: 6,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

interface PetitionRemainingProgressProps {
  signaturesNeeded: number;
  signaturesReceived: number;
  displayRemainingNumber?: boolean;
}

const PetitionRemainingProgress = ({
  signaturesNeeded,
  signaturesReceived,
  displayRemainingNumber,
}: PetitionRemainingProgressProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const remaining = Math.max(signaturesNeeded - signaturesReceived, 0) || "no";

  return (
    <div className={classes.wrapper}>
      {displayRemainingNumber && (
        <Typography variant="body2" mb={2}>
          {remaining === 1
            ? t("Petitions.Need1MoreSignature")
            : t("Petitions.NeedMoreSignatures", { nr: remaining })}
        </Typography>
      )}
      <BorderLinearProgress
        variant="determinate"
        value={Math.min((signaturesReceived / signaturesNeeded) * 100, 100)}
      />
    </div>
  );
};

export default PetitionRemainingProgress;
