import { useRouter } from "next/router";
import React from "react";

import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import { useStyles as useContentCardStyles } from "@/common/components/ContentCard.styles";
import Link from "@/common/components/Link";

import ArticlesIcon from "@/common/icons/ArticlesIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useBlogsFollowersStore } from "@/blogs/hooks";

import { USERS_REGISTER_URL } from "@/common/constants";
import {
  UserBaseFragment,
  BlogCardFragment,
  BlogCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

import { getFullAuthorName } from "../services/fullName";

const useStyles = makeStyles()(() => ({
  mediaIcon: {
    width: "52%",
    height: "52%",
    color: "#3F3D56",
    fill: "#3F3D56",
  },
  belongingLink: {
    fontWeight: 800,
  },
  mediaIconVertical: {
    height: "72px",
  },
  text: {
    fontSize: ".9375rem",
    lineHeight: "1.25rem",
    WebkitLineClamp: "2",
    display: "-webkit-box",
    overflowWrap: "anywhere",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const BlogCard = ({
  blog,
  hideBelonging,
  showAuthor,
  hideHeader = false,
  hideHeaderIcon = false,
  cardType,
  ...props
}: {
  blog: BlogCardFragment | BlogCardSlimFragment;
  showAuthor?: boolean;
  hideBelonging?: boolean;
  hideHeader?: boolean;
  hideHeaderIcon?: boolean;
} & Partial<ContentCardProps>) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { classes: contentCardClasses } = useContentCardStyles();
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const { toggleFollow, undoFollowRequest } = useBlogsFollowersStore(blog);
  const router = useRouter();

  const hasParentGroup = !!blog.activityGroup?.url;

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (blog.author?.isMe) {
      router.push(`/blogs/${blog.slug}/edit`);
      return;
    }
    if (blog.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      toggleFollow();
    }
  };

  const Media = blog.cover
    ? () => (
        <img
          src={blog.cover?.url}
          alt="Blog"
          className={cx(contentCardClasses.mediaCover, {
            [contentCardClasses.mediaCoverVertical]: cardType === "vertical",
          })}
        />
      )
    : () => (
        <ArticlesIcon
          className={cx(classes.mediaIcon, {
            [classes.mediaIconVertical]: cardType === "vertical",
          })}></ArticlesIcon>
      );

  const getActionText = () => {
    if (session?.user?.id === blog.author?.id) {
      return t("Blog.EditBlog");
    }
    if (blog.follows.hasBeenRequestedByMe) {
      return t("Generic.Requested");
    }
    return blog.follows.isFollowedByMe ? t("Generic.Unfollow") : t("Generic.Follow");
  };

  const count = blog.follows.followers.count;

  const followersText =
    count === 1
      ? t("Common.follower_count", { count })
      : t("Common.follower_count_plural", { count });
  const getSubtitleText = () => {
    const subtitle =
      count === 1
        ? t("Common.follower_count", { count })
        : t("Common.follower_count_plural", { count });

    return (
      <>
        {showAuthor && blog.author && (
          <>
            {t("Generic.CreatedBy")} {getFullAuthorName(blog.author as UserBaseFragment)}
            &nbsp;•&nbsp;
          </>
        )}

        {subtitle}
      </>
    );
  };

  return (
    <ContentCard
      header={hideHeader ? undefined : t("Discover.Blogs")}
      headerIcon={hideHeaderIcon ? undefined : ArticlesIcon}
      headerIconVertical={<ArticlesIcon color="#3f3d56" />}
      title={blog.title}
      subtitle={getSubtitleText()}
      text={blog.description ?? ""}
      actionTextUncolored={
        (blog.follows.isFollowedByMe || blog.follows.hasBeenRequestedByMe) &&
        session?.user?.id !== blog.author?.id
      }
      media={<Media />}
      actionText={getActionText()}
      onAction={handleActionClick}
      descriptionComponent={
        <>
          {hideBelonging ||
            (blog.activityGroup && (
              <>
                {t("Generic.CreatedBy")}{" "}
                <Link href={blog.activityGroup.url || ""} passHref>
                  <a className={classes.belongingLink}>{blog.activityGroup.title}</a>
                </Link>
              </>
            ))}
          {blog.description && <p className={classes.text}>{blog.description}</p>}
        </>
      }
      titleLink={blog.url}
      dataCy="BlogCard"
      cardType={cardType}
      createdByLabel={hasParentGroup ? blog.activityGroup?.title : blog.author?.displayName}
      createdByLink={hasParentGroup ? blog.activityGroup?.url : blog.author?.url}
      createdByIsVerified={!hasParentGroup && blog.author?.verified}
      followersText={followersText}
      canShowFollowersList={count > 0}
      activityId={blog.id}
      activityType={ExtendedActivityType.Blog}
      numberFollowers={count}
      {...props}
    />
  );
};
export default BlogCard;
